<template>
  <div>
  <v-card
      v-if="item"
      outlined
      :to="detailsUrl"
  >

    <div class="d-flex align-center pa-2">
      <div>
        <v-img
            :lazy-src="item.image ? item.image.filepath : ''"
            :src="item.image ? item.image.filepath : ''"
            max-height="200"
            max-width="80"
            class="rounded"
            :alt="item.image ? item.image.name : ''"
        >
        </v-img>
      </div>
      <v-col cols="7" class="pa-2">
        <div class="font-weight-bold">
          {{ item.name | text_capitalize}}
        </div>
        <div class="text-subtitle-2 grey--text">
          {{ item.description }}
        </div>
      </v-col>
      <v-col cols="4" class="font-weight-bold">
        {{(!show_price ? item.unit_price:0) | money}}
      </v-col>
    </div>
  </v-card>
    <v-skeleton-loader
        v-else
        v-bind="{
        class: 'mb-6',
        boilerplate: true,
        elevation: 2,
      }"
        type="article"
    ></v-skeleton-loader>

  </div>
</template>

<script>
export default {
  name: "MenuItem",
  props: ['item', 'show_price'],
  computed: {
    detailsUrl(){
      return {
        name: 'MobileMenuItem',
        params: {item: this.item}
      }
    }
  }
}
</script>

<style scoped>

</style>