<template>
  <div id="menu">

    <v-card color="#0F4A6D" class="hidden-md-and-up d-flex flex-row justify-space-between" tile elevation="0">
      <v-card-text class="white--text text-uppercase">Saldo disponible</v-card-text>
      <v-card-text class="white--text text-uppercase text-right">{{wallet | money}}</v-card-text>
    </v-card>
    <div v-if="!error">
      <v-container>
        <v-chip-group
            v-model=categoryToShow
        >
          <v-chip
              v-for="(category,index) in categories"
              :key="index"
              large
              class="ma-2"
              label
              outlined
              active-class="selected-item"
          >
            <v-icon left>
              mdi-{{ category.icon }}
            </v-icon>
            {{category.name | text_capitalize}}
          </v-chip>
        </v-chip-group>
        <div>
          <menu-item
              v-for="(item, index) in getList()"
              :key="index"
              :item="item"
              :show_price="selectBebida"
              class="my-3"
          ></menu-item>
        </div>
      </v-container>
      <v-container>
        <v-btn tile depressed x-large class="bottom-btn orange white--text " to="/mobile/restaurant/cart" :color="cart > 0 ? 'orange': 'grey lighten-1'">
          <div class="text-capitalize">carrito</div>
          <div class="text-uppercase text-right">{{cart | money}}</div>
        </v-btn>
      </v-container>
    </div>
    <div v-else>
      <div class="text-center mt-10 text-h5"> {{error}} </div>
    </div>
    <v-overlay v-show="newOrder" id="mobile_number">
      <div class="d-flex justify-center flex-column">
        <v-card class="white ma-5 pa-6" elevation="2">
          <v-card-subtitle class="black--text">Introduzca su número de teléfono móvil. Solo lo utilizaremos para avisarle cuando su pedido esté listo.</v-card-subtitle>
          <v-row>
            <v-text-field
                prepend-inner-icon="mdi-phone"
                autofocus
                outlined
                placeholder="+34xxxxxxxxx"
                v-model="cellphone"
            ></v-text-field>
          </v-row>
          <v-card-actions>
            <v-btn block color="orange" flat @click="getViewData">Ingresar</v-btn>
          </v-card-actions>
        </v-card>
      </div>
    </v-overlay>
  </div>
</template>

<script>
import MenuItem from "../../../components/restaurant/MenuItem";
import {Reservation, Restaurant} from "@/services/api";
export default {
  name: "MobileMenu",
  components: {MenuItem},
  data(){
    return{
      wallet: 0,
      categories:[],
      categoryToShow: 0,
      items:Array(5). fill(false),
      error: false,
      newOrder:false,
      cellphone: null,
      bebidasMenu: null,
    }
  },
  created() {
    if(this.session){
      this.loadLogIn()
    }
  },
  mounted() {
    if(!this.$store.state.phoneNumber){
      this.newOrder = true
    }else{
      this.getViewData()
    }

  },
  methods: {
    getViewData(){
      let cellphoneNumber = this.$store.state.phoneNumber
      if(!cellphoneNumber) {
        this.$store.commit('setPhone', this.cellphone)
        this.newOrder = false
        cellphoneNumber = this.cellphone
      }
      if(!this.order){
        this.getActualEvent().then(
            amphiEvent => {
              if(amphiEvent) {
                this.$store.commit('setReservation', amphiEvent)
                this.requestRestaurant().createOrder({
                  phone_number: cellphoneNumber,
                  amphi_reservation_id: amphiEvent.id
                })

                    .then(
                        restaurantOrder => {
                          const order = restaurantOrder.data.data
                          this.$store.commit('setOrder', order)
                          this.wallet = order.available_discount
                          this.$store.commit('setLoading', false)
                        }
                    )
                    .catch(error =>{
                        if(error.response.status === 405){
                          this.createOrderNoEvent(cellphoneNumber)
                        }
                    })
              }else{
                this.createOrderNoEvent(cellphoneNumber)
              }
            }
        ).catch(
            err =>{
              this.$store.commit('setLoading', false)
              this.error = err
              alert('Ha Habido un Error')
            }
        )
      }
      if(this.$store.state.articles.length > 0){
        this.items = this.$store.state.articles
        this.$store.commit('setLoading', false)
      }
      if(this.$store.state.categories.length > 0){
        this.categories = this.$store.state.categories
        this.$store.commit('setLoading', false)
      }

      let item =  this.requestRestaurant().getItems()
      let categories =  this.requestRestaurant().getCategory()

      // orderID
      return Promise.all([item,categories]).then(
          responses => {
            this.items = responses[0].data.data
            this.categories = responses[1].data.data.sort((a,b) => (a.name > b.name) ? 1 : ((b.name > a.name) ? -1 : 0))
            this.category = this.categories[0].id
            this.$store.commit('setItems', this.items)
            this.$store.commit('setCategories', this.categories)

          }
      )
    },
    requestRestaurant(){
      return Restaurant(this.user.token_type, this.user.access_token)
    },
    requestReservation(){
      return Reservation(this.user.token_type, this.user.access_token)
    },
    loadLogIn(){
      const user = {
        access_token : this.session,
        token_type: 'Bearer'
      }
      this.$store.commit('setUser', user)
    },
    createOrderNoEvent(cellphoneNumber){
      return this.requestRestaurant().createOrder({
        phone_number: cellphoneNumber,
        totem: true
      })
          .then(
              restaurantOrder => {
                const order = restaurantOrder.data.data
                this.$store.commit('setOrder', order)
                this.wallet = 0
                this.$store.commit('setLoading', false)
              }
          )
    },
    getActualEvent(){
      return new Promise((resolve, reject)=>{
        return this.requestReservation().all().then(
            response => {
              let result = response.data.data
              let startDate = new Date()
              let endDate = new Date()
              startDate.setHours(startDate.getHours() - 1);
              endDate.setHours(endDate.getHours() + 1);
              resolve(
                  result.filter(reservation => {
                    let event_schedule = (new Date(reservation.event_schedule_id.schedule))
                    return (event_schedule.getTime() >= startDate.getTime() && event_schedule.getTime() <= endDate.getTime())
                  })[0]
              )
            }
        )
            .catch(reject)
      })
    },
    getList() {
      if(this.selectBebida){
        this.requestRestaurant()
            .getDrinks(this.selectBebida.id)
            .then(
                drinksResponse => {
                  this.bebidasMenu = drinksResponse.data.articles
                  this.$store.commit('setLoading', false)
                }
            )
        return this.items.filter(value => this.bebidasMenu.includes(value.bdp_code))
      } else if(this.items[0]){
        return this.items.filter(
            item => {
              return  this.categories.length > 0 && item.category.id === this.categories[this.categoryToShow].id
            }
        ).sort((a,b)=>{
          return  a.name > b.name
        })
      }else{
        return this.items
      }

    },

  },
  computed:{
    cart(){
      const items = this.$store.state.order ? this.$store.state.order.items : [];
      let price = 0;
      items.forEach(item =>{
        if(!item.item_related_id){
          price += item.quantity * item.unit_price
        }
      })
      return price
    },

    user(){
      return this.$store.state.user || {}
    },
    session(){
      return this.$route.query.sessionid || null
    },
    selectBebida(){
      return this.$store.state.relatedItem || false
    },
    order(){
      return this.$store.state.order
    }

  }
}
</script>

<style lang="scss">
#menu{
  .v-input .v-input__slot {
    background: rgba(149, 149, 149, 0.47);
  }
  .selected-item{
    background-color: #FF9900 !important;
    color: white;
  }
  .bottom-btn{
    position:fixed;
    bottom: 0;
    width: 93%;
    .v-btn__content{
      justify-content: space-between;
    }
  }
}
</style>